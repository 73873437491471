import React from "react";

const Spinner = () => {
  return (
    <section className="spinner-container">
      <div className="spinner">
        <div className="spinner-sector spinner-sector-red"></div>
        <div className="spinner-sector spinner-sector-green"></div>
        <div className="spinner-sector spinner-sector-sky"></div>
      </div>
    </section>
  );
};

export default Spinner;
